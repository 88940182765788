import React from "react"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"
import {
  UnstyledLink,
  Link,
  Section,
  SectionHeading,
  Pagehead,
  PageTitle,
  Breadcrumbs,
  ContactInformation,
  ContactKey,
  ContactValue,
  CallToAction,
} from "../components/shared"
import { contactTheme } from "../components/theme"
import { narrowNoBreakSpace } from "../util/typography"
import directionsMapLindlar from "../images/karte-lindlar.svg"
import directionsMapCologne from "../images/karte-koeln.svg"

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "heading heading . . . ."
    "direct direct form form form ."
    "hours hours map1 map1 map2 map2"
    "hours hours address1 address1 address2 address2";
  gap: 2rem;

  font-size: 1.1rem;

  @media only screen and (max-width: 960px) {
    grid-template-columns: 3fr repeat(3, 2fr);
    grid-template-areas:
      "heading . . ."
      "direct form form form"
      "hours form form form"
      "address1 map1 map1 map1"
      "address2 map2 map2 map2";
    gap: 1rem;
  }

  @media only screen and (max-width: 666px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "heading heading ."
      "direct direct ."
      "form form form "
      "hours hours ."
      "address1 address1 ."
      "map1 map1 map1"
      "address2 address2 ."
      "map2 map2 map2";
    gap: 1rem;
  }
`

const Heading = styled(SectionHeading)`
  font-size: 1.8rem;
  margin-bottom: 1em;
`

const MainHeading = styled(Heading)`
  grid-area: heading;
  margin-bottom: 0;
`

const DirectContact = styled.div`
  grid-area: direct;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
`

const PhoneExtensionsLink = styled(CallToAction)`
  font-size: inherit;
`

const FormWrapper = styled.div`
  grid-area: form;
`

const BusinessHours = styled.div`
  grid-area: hours;
`

const Map1 = styled.div`
  grid-area: map1;
`

const Map2 = styled.div`
  grid-area: map2;
`

const Address1 = styled.div`
  grid-area: address1;
`

const Address2 = styled.div`
  grid-area: address2;
`

const MapImage = styled.img`
  width: 100%;
  background-color: #ffffff;
`

const Address = styled.address`
  font-style: normal;
`

const Contact = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <Pagehead>
        <PageTitle>
          Schreiben Sie, rufen Sie uns an oder kommen Sie zu Besuch.
        </PageTitle>
        <Breadcrumbs nodes={[{ title: "Kontakt" }]} />
      </Pagehead>
      <ThemeProvider theme={contactTheme}>
        <Section>
          <ContactGrid>
            <MainHeading>Treten Sie mit uns in Kontakt</MainHeading>

            <DirectContact>
              <Link to="mailto:info@holzbauplanung.de">
                info@holzbauplanung.de
              </Link>
              <UnstyledLink to="tel:+4922669018900">
                02266{narrowNoBreakSpace}/{narrowNoBreakSpace}901890
                {narrowNoBreakSpace}-{narrowNoBreakSpace}0
              </UnstyledLink>
              <PhoneExtensionsLink to="/team">
                direkte Durchwahl
              </PhoneExtensionsLink>
            </DirectContact>

            <FormWrapper>
              <ContactForm />
            </FormWrapper>

            <BusinessHours>
              <Heading>Bürozeiten</Heading>
              <ContactInformation>
                <ContactKey>Mo.-Do.</ContactKey>
                <ContactValue>08:00-16:00</ContactValue>

                <ContactKey>Fr.</ContactKey>
                <ContactValue>08:00-13:00</ContactValue>
              </ContactInformation>
            </BusinessHours>

            <Map1>
              <MapImage src={directionsMapLindlar} alt="Karte Lindlar" />
            </Map1>

            <Address1>
              <Heading>Niederlassung Lindlar</Heading>
              <Address>
                Sülztalstraße 84
                <br />
                51789 Lindlar
              </Address>
            </Address1>

            <Map2>
              <MapImage src={directionsMapCologne} alt="Karte Köln" />
            </Map2>

            <Address2>
              <Heading>Niederlassung Köln</Heading>
              <Address>
                Klingelpütz 22
                <br />
                50670 Köln
              </Address>
            </Address2>
          </ContactGrid>
        </Section>
      </ThemeProvider>
    </Layout>
  )
}

// TODO: title, metaDescription?

export default Contact
